<template>
  <div class="skeleton" :style="{ 'border-radius': borderRadius + 'px' }"></div>
</template>

<script>
export default {
  name: "Skeleton",
  props: {
    borderRadius: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.skeleton {
  position: absolute;
  inset: 0;
  background: linear-gradient(0.25turn, transparent, #f8f7f7, transparent),
    linear-gradient(#eee, #eee),
    radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%),
    linear-gradient(#eee, #eee) no-repeat;
  background-size: 315px 250px, 315px 180px, 100px 100px, 225px 30px;
  background-position: -315px 0, 0 0, 0px 190px, 50px 195px;
  animation: skeleton-animation 1.5s infinite;
  content: "";
  z-index: 99;
  @keyframes skeleton-animation {
    to {
      background-position: 315px 0, 0 0, 0 190px, 50px 195px;
    }
  }
}
</style>
